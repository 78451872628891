<template>
  <b-container>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <FilterComponent
        :store="store" 
        :filter="filter"
        @filter-now="filter = '';changePage(1);" 
        @change-page="changePage($event)"
        @add-new="add()"
        @filter-word="filter = $event"
      />
      <b-alert
        show
        variant="danger"
        dismissible
      >
        <strong>Note:</strong> Also available at <a href="https://ssiplengg.com">https://ssiplengg.com</a>.
      </b-alert>

      <CrudComponent
        :store.sync="store"
        :filter="filter"
        :fetch="fetchItems"
        :formerr.sync="formError"
        @editNow="edit"
        @showNow="show"
        @delNow="del"
        @changeNow="changePage"
      />
    </b-overlay>

    <!-- Edit modal -->
    <b-modal
      id="modal"
      centered
      :title="form.formTitle"
      hide-footer
    >
      <b-alert
        v-if="alert.message"
        :show="5"
        dismissible
        :variant="alert.type"
        @dismissed="clearAlert"
      >
        {{ alert.message }}
      </b-alert>

      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          label="Is Vacant ?" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="is_vacant"
        >
          <b-form-select 
            v-model="$v.form.item.is_vacant.$model"
            size="sm"
            :state="validateState('is_vacant')"
            :options="[{text: 'True', value: 1}, {text: 'False', value: 0}]"
          />

          <b-form-invalid-feedback id="city-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Name" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="name"
        >
          <b-form-input 
            id="name"
            v-model="$v.form.item.name.$model"
            size="sm"
            name="name"
            :state="validateState('name')"
            aria-describedby="name-live-feedback"
          />

          <b-form-invalid-feedback
            id="name-live-feedback"
          >
            This is a required field and must be at least 3 characters.
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- <b-form-group label="Email" 
              label-cols-sm="4"
              label-cols-lg="3"  
              label-for="email">
                <b-form-input 
                  size="sm"
                    id="email"
                    name="email"
                    v-model="$v.form.item.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="email-live-feedback">
                    This is a required field.
                </b-form-invalid-feedback>
            </b-form-group> -->
           
        <b-form-group
          label="Bill Unit No" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="billunitnumber"
        >
          <b-form-input 
            id="billunitnumber"
            v-model="$v.form.item.billunitnumber.$model"
            size="sm"
            name="billunitnumber"
            :state="validateState('billunitnumber')"
            aria-describedby="billunitnumber-live-feedback"
          />

          <b-form-invalid-feedback id="billunitnumber-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
           
        <b-form-group
          label="Quarter" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="quarter"
        >
          <b-form-input 
            id="quarter"
            v-model="$v.form.item.quarter.$model"
            size="sm"
            name="quarter"
            :state="validateState('quarter')"
            aria-describedby="quarter-live-feedback"
          />

          <b-form-invalid-feedback id="quarter-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group> 
        <b-form-group
          label="Address" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="address"
        >
          <b-form-input 
            id="address"
            v-model="$v.form.item.address.$model"
            size="sm"
            name="address"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          />

          <b-form-invalid-feedback id="address-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
            
              
        <b-form-group
          label="Emp Num"
          label-cols-sm="4"  
          label-cols-lg="3"
          label-for="emp_num"
        >
          <b-form-input 
            id="emp_num"
            v-model="$v.form.item.emp_num.$model"
            size="sm"
            name="emp_num"
            :state="validateState('emp_num')"
            aria-describedby="emp_num-live-feedback"
          />

          <b-form-invalid-feedback id="emp_num-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
              
        <b-form-group
          label="Designation" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="designation"
        >
          <b-form-input 
            id="designation"
            v-model="$v.form.item.designation.$model"
            size="sm"
            name="designation"
            :state="validateState('designation')"
            aria-describedby="designation-live-feedback"
          />

          <b-form-invalid-feedback id="designation-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
              
        <b-form-group
          label="Department" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="department"
        >
          <b-form-input 
            id="department"
            v-model="$v.form.item.department.$model"
            size="sm"
            name="department"
            :state="validateState('department')"
            aria-describedby="department-live-feedback"
          />

          <b-form-invalid-feedback id="department-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Code" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="city_id"
        >
          <b-form-select 
            v-model="$v.form.item.city_id.$model"
            size="sm"
            :state="validateState('city_id')"
            :options="store.cities"
          />

          <b-form-invalid-feedback id="city-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
            
        <b-form-group  
          label="State:"
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="state"
        >
          <b-form-select
            v-model="$v.form.item.state_id.$model"
            size="sm" 
            :state="validateState('state_id')"
            :options="store.stateitems"
          />
        </b-form-group>
        <b-form-group  
          label="QTR Type :"
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="category"
        >
          <b-form-select 
            v-model="$v.form.item.category_id.$model"
            size="sm" 
            :state="validateState('category_id')"
            :options="store.categories"
          />
        </b-form-group>

        <div class="text-center">
          <b-button
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <!-- <b-button class="ml-2" @click="resetForm()">Reset</b-button> -->
        </div>
      </b-form>
    </b-modal>
    <!-- Show modal -->
    <b-modal
      id="show-modal"
      centered
      title="Show Item"
      ok-only
      size="md"
    >
      <ul class="list-group">
        <li
          v-for="(val, index) in form.item"
          :key="index"
          class="list-group-item"
        >
          {{ index + ' : ' + JSON.stringify(val, 0, 2) }}
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>

<style scoped>

</style>

<script>
import helper from "@/mixins/helper";
import { mapState, mapActions, mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import FilterComponent from '@/components/FilterComponent.vue';
import CrudComponent from '@/components/CrudComponent.vue';

export default {
  name: 'Customers',
  components:{
    FilterComponent,
    CrudComponent
  },
  mixins: [validationMixin, helper],
  data() {
    return {
      form: {
        formTitle: 'Create Item',
        mode: 'add',
        item: {
          id: null,
          name: null,
          // email: null,
          billunitnumber: '',
          quarter: '',
          address: '',
          emp_num: null,
          designation: null,
          department: null,
          state_id: null,
          city_id: null,
          category_id: null,
          is_vacant: false
        }
      },
      isBusy: false,
      filter: ''
    }
  },
  computed: {
    ...mapState({ alert: state => state.alert }),
    ...mapState("customer", ['formError']),
    ...mapGetters("customer", ['store']),
  },
  watch: {
    formError(newVal){
      if(!newVal){
        this.$bvModal.hide('modal');
      }
    }
  },
  validations: {
    form: {
      item: { 
        name: { required, minLength: minLength(3) }, 
        // email: { required }, 
        billunitnumber: { }, 
        quarter: { }, 
        address: { }, 
        emp_num: { 
          required : requiredIf(function(){
            return !this.form.item.is_vacant;
          }) 
        }, 
        designation: { }, 
        department: { }, 
        state_id: { }, 
        city_id: { }, 
        category_id: { }, 
        is_vacant: { required }
      }
    }
  },
  mounted(){
    setTimeout(this.setTT, 2000);
  },
  methods: {
    ...mapActions("customer", [
      "fetchItems", 
      "setFilter", 
      "addItem", 
      "deleteItem", 
      "updateItem", 
      "changePage", 
      "setStates", 
      "setCities", 
      "setCategories"
    ]),
    ...mapActions({ clearAlert: 'alert/clear' }),

    setTT(){
      this.setStates();
      this.setCities();
      this.setCategories();
    },
    // 1. Prepare modal for creation of item.
    add() {
      this.$v.$reset();
      this.form.item = {
        id: null,
        name: null,
        // email: null,
        billunitnumber: null,
        quarter: null,
        address: null,
        emp_num: null,
        desingation: null,
        department: null,
        state_id: null,
        city_id: null,
        category_id: null,
        is_vacant: false,
      }
      this.form.formTitle = 'Create Item:';
      this.form.mode = 'add';
      this.$root.$emit('bv::show::modal', 'modal')
    },
    // Prepare model for updation.
    edit(item, button) {
      this.form = {
        formTitle: 'Edit Item:',
        mode: 'update',
        item: JSON.parse(JSON.stringify(item))
      };
      this.$root.$emit('bv::show::modal', 'modal', button)
    },
    // 2. Validation after creating form.
    validateState(name) {
      const { $dirty, $error } = this.$v.form.item[name];
      return $dirty ? !$error : null;
    },
    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.clean(this.form.item);
      if(this.form.mode == 'update'){
        if(this.form.item.is_vacant==1){
          this.form.item.emp_num = 'VACANT';
        }
        this.updateItem(this.form.item);
      }else{
        this.addItem(this.form.item);
      }
    },
    // 4. Show Item details in another Modal.
    show(item, index, button) {
      this.form.item = item;
      this.$root.$emit('bv::show::modal', 'show-modal', button)
    },
    // 5. Delete the item.
    del(id) {
      let res = window.confirm("Are you sure?");
      if(res) this.deleteItem(id);
    },
  }
}
</script>
